.host-stage-selector {
  height: 50px;
  padding-top: 3px;
  margin-right: 26px;
  box-sizing: border-box;
  cursor: pointer;
}
.host-stage-selector:hover {
  opacity: 0.8;
}
.host-stage-selector > * {
  display: inline-block;
}

.blink-opacity {
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Hide the download button by default. Only needed for Firefox. */
.download-button {
  display: none;
  text-decoration: none;
  margin-top: 20px;
}
@supports (-moz-appearance: none) {
  .download-button {
    display: block;
  }
}

.download-button button {
  color: black;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  height: 50px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
}

.app {
  background-color: #161616;
}
.content {
  padding-top: 80px;
}
.host-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  padding: 50px;
  gap: 50px;
  flex-wrap: wrap;
}
.host-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 200px;
}
.host-name-wrapper {
  flex: 1;
}
.host-tab-container .tab-content {
  padding: 25px 50px 50px 50px;
}
.playground-tab-container {
  padding: 0 50px 50px 50px;
  margin: 0 -50px -50px -50px;
  width: 100%;
}
.playground-tab-container .tab-container {
  margin: 0 -50px -50px -50px;
  width: calc(100% + 100px);
}
.row {
  display: flex;
  gap: 20px 100px;
  flex-wrap: wrap-reverse;
  margin-bottom: 25px;
}
.row.row-wrap {
  flex-wrap: wrap;
}
.row:last-of-type {
  margin-bottom: 0;
}
.row > * {
  flex: 1;
}
.row > *:first-child {
  flex-basis: calc(60% - 100px);
}
.row > *:last-child {
  flex-basis: calc(40% - 100px);
}

@media (max-width: 999px) {
  .row {
    gap: 20px;
  }
  .row > *:first-child,
  .row > *:last-child {
    flex-basis: 100%;
  }
}

.side-by-side {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-left: 10px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4caf50;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.toggle-switch.small {
  width: 30px;
  height: 15px;
  margin-left: 8px;
}

.toggle-switch.small .slider {
  border-radius: 15px;
}

.toggle-switch.small .slider:before {
  height: 11px;
  width: 11px;
  left: 2px;
  bottom: 2px;
  border-radius: 50%;
}

input:checked + .slider:before {
  transform: translateX(15px);
}

.avatar-tap-to-change-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: black;
  opacity: 0;
  z-index: 99;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar-tap-to-change-overlay:hover {
  opacity: 0.7;
}

.image-picker {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.image-picker input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;

  cursor: pointer;
  z-index: 2;
}

.image-picker-label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  width: 170px;
  height: 33px;
  background-color: green;
  color: white;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.1s ease, box-shadow 0.1s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 1;
  padding: 5px 8px;
}

.image-picker-label:hover {
  background-color: grey;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.image-picker-label:active {
  background-color: green;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
.api-response p span {
  background: #161616;
  padding: 5px;
  border-radius: 8px;
  user-select: text;
  line-height: 28px;
}
.api-response p {
  user-select: none;
  margin: 5px 0;
}
.api-response h4 {
  margin: 10px 0;
}

.api-response-container {
  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 8px;
}
.api-response p.story-response {
  line-height: 27px;
  user-select: text;
}

.apple-store-subscription-text{
  padding-top: 5px;
}
.apple-store-subscription-text.danger{
  color: #ED4F4F;
}
.apple-store-subscription-text.success{
  color: #4caf50;
}