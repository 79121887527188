table {
  border-collapse: collapse;
}
th {
  border-bottom: 1px solid #ffffff1a;
  padding: 8px;
  text-align: left;
}
td {
  padding: 8px;
  text-align: left;
  color: #b6b6b6;
  font-size: 12px;
  font-weight: 400px;
}

table.analytics-table {
  width: auto;
}
.recharts-default-tooltip {
  background-color: #434343 !important;
}
.custom-tooltip {
  background-color: #434343;
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
}
.custom-tooltip .label {
  margin: 0;
}
.recharts-tooltip-cursor {
  fill: #ffffff11;
}
.charts {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
@media (min-width: 1024px) {
  .charts {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 1440px) {
  .charts {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.charts > div {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.charts table td {
  font-size: 18px;
}
